import React from "react";
import { LawModel } from "@/src/models/lawModel";
import CardLaw from "@/src/components/molecules/cardLaw";

interface GridViewProps {
  data: LawModel[];
  limit?: number;
  cols?: number;
}

const getGridColClass = (cols: number) => {
  switch (cols) {
    case 1:
      return "lg:grid-cols-1";
    case 2:
      return "lg:grid-cols-2";
    case 3:
      return "lg:grid-cols-3";
    case 4:
      return "lg:grid-cols-4";
    case 5:
      return "lg:grid-cols-5";
    case 6:
      return "lg:grid-cols-6";
    default:
      return "lg:grid-cols-1";
  }
};

const GridViewLaw: React.FC<GridViewProps> = ({
  data,
  limit = 2,
  cols = 2,
}) => {
  const gridColClass = getGridColClass(cols);

  return (
    <div
      className={`grid place-items-center gap-4 md:grid-cols-1 ${gridColClass}`}
    >
      {data.slice(0, limit).map((item) => (
        <div key={item.id} className="w-full">
          <CardLaw law={item} />
        </div>
      ))}
    </div>
  );
};

export default GridViewLaw;
