import React, { useState, useEffect } from "react";
import Link from "next/link";
import GridViewLaw from "@/src/components/templates/gridViewLaw";
import { data } from "@/src/repositories/localDataRepository";
import { LawModel } from "@/src/models/lawModel";
import ProductOfLawRepository from "@/src/repositories/productOfLawsRepository";
import SkeletonDoc from "../atoms/skeletonDoc";

const NewDocuments = () => {
  const [documents, setDocuments] = useState<LawModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const documentRepository = new ProductOfLawRepository();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const docs = await documentRepository.fetchLatest(1, 2);
        setDocuments(docs);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex w-full flex-row flex-wrap">
        <div className="md:w-full lg:w-1/3">
          <div className="w-full p-4 text-[#171717]">
            <div className="w-full">
              <h1 className="text-6xl font-bold">Peraturan Terbaru</h1>
              <h3 className="text-2x mt-1 text-left">
                Lihat dan unduh produk hukum terbaru yang diterbitkan oleh DPRD
                Provinsi Bali
              </h3>
            </div>
            <button className="mt-4 rounded-xl bg-primary px-4 py-2 text-white">
              <Link href="/dokumen/peraturan-terbaru">
                <div className="flex items-center justify-center gap-2">
                  Lihat Semua
                  <img
                    src="assets/icons/u_arrow-up-right.png"
                    alt="Arrow Image"
                    className="h-5 w-5"
                  ></img>
                </div>
              </Link>
            </button>
          </div>
        </div>
        <div className="row-span-2 row-start-1 row-end-4 w-full items-center justify-center sm:w-full md:w-full lg:w-2/3">
          {/* <GridViewLegislation data={data} /> */}
          {loading ? (
            <SkeletonDoc number={2} columns={{ md: 1, lg: 2, xl: 2 }} />
          ) : (
            <GridViewLaw data={documents} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewDocuments;
