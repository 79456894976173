import React, { useState, FormEvent, useEffect } from "react";
import Mail from "../atoms/mail";
import Contact from "../atoms/contact";
import { ProfileModel } from "@/src/models/generalModel";
import GeneralRepository from "@/src/repositories/generalRepository";
import ErrorTemplate from "../templates/errorTemplate";

const ContactMail = () => {
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<ProfileModel | null>(null);
  const [error, setError] = useState<string | null>(null);

  const generalRepository = new GeneralRepository();

  useEffect(() => {
    setIsLoading(true);

    generalRepository
      .profile()
      .then((response) => {
        if (response.data) {
          // console.log(response.data);
          setProfile(response.data);
        } else {
          setProfile(null);
        }
      })
      .catch((err) => setError((err as Error).message))
      .finally(() => setIsLoading(false));
  }, []);

  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex flex-row flex-wrap">
      {profile ? (
        <>
          <div className="md:w-full lg:w-1/2 lg:pr-2">
            <Contact data={profile} />
          </div>
          <div className="md:w-full lg:w-1/2 lg:pl-2">
            <Mail />
          </div>
        </>
      ) : (
        <div className="h-80 w-full animate-pulse rounded-md bg-slate-200"></div>
      )}
    </div>
  );
};

export default ContactMail;
