import React from "react";
import PlaceSvg from "@/public/assets/icons/svgs/u_location-pin-alt-white.svg";
import PhoneSvg from "@/public/assets/icons/svgs/u_phone-alt-white.svg";
import AtSvg from "@/public/assets/icons/svgs/u_at-white.svg";
import { ProfileModel } from "@/src/models/generalModel";
import OperationalHour from "../atoms/operationalHour";

type Props = {
  children?: React.ReactNode;
  data: ProfileModel;
};

const Contact: React.FC<Props> = ({ children, data }) => {
  return (
    <div className="safari-fallback relative block h-[60vh] rounded-md bg-gray-900 bg-opacity-50 mix-blend-overlay">
      <img
        src="assets/images/contact.jpg"
        alt="BannerImage"
        className="absolute w-full rounded-md object-cover object-right opacity-10 lg:h-[60vh]"
      />
      <div className="absolute -z-10 h-[60vh] w-full rounded-md bg-gradient-to-r from-black via-black to-neutral-800 lg:h-[60vh]" />
      <div className="relative z-10 rounded-md bg-black bg-opacity-0 px-6 py-11 text-white">
        <h6>Kontak Kami</h6>
        <h1 className="text-5xl md:w-full lg:w-1/2">{data.name}</h1>
        <div className="mt-4 flex flex-col">
          <div className="flex flex-row gap-2 py-2">
            <PlaceSvg className="hidden lg:block" />
            <span>{data.address}</span>
          </div>
          <div className="flex flex-row gap-2 py-2">
            <PhoneSvg className="hidden lg:block" />
            <span>{data.phone}</span>
          </div>
          <div className="felx-row flex gap-2 py-2">
            <AtSvg className="hidden lg:block" />
            <span>{data.email}</span>
          </div>
        </div>
      </div>

      <style jsx global>{`
        .safari-fallback {
          @supports (-webkit-appearance: none) {
            mix-blend-mode: normal; /* Disable blend mode in Safari */
            background-color: rgba(
              0,
              0,
              0,
              0.5
            ); /* Apply bg-opacity-50 as a fallback */
          }
        }
      `}</style>
    </div>
  );
};

export default Contact;
