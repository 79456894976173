import { NewsModel } from "../models/newsModel";

interface DocumentsResponse {
  code: number;
  status: string;
  message: string;
  page: number;
  limit: number;
  total: number;
  last_page: number;
  data: NewsModel[];
}

interface DetailResponse {
  code: number;
  status: string;
  message: string;
  data: NewsModel;
}

export default class NewsRepository {
  newsList: Array<NewsModel> = [];
  news: NewsModel | null = null;

  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as String) + "v1";
    // console.log("API Base URL:", this.baseUrl);
  }

  async fetchAll({
    page = 1,
    limit = 12,
    q = "",
    sort = "",
  }: {
    page: number;
    limit: number;
    q?: string;
    sort?: string;
  }): Promise<{ data: NewsModel[]; last_page: number; total: number }> {
    return new Promise((resolve, reject) => {
      const url = new URL(`${this.baseUrl}/news`);

      // Create a URLSearchParams object to hold the query parameters
      const params = new URLSearchParams();
      if (page) params.append("page", page.toString());
      if (limit) params.append("limit", limit.toString());
      if (q) params.append("q", q);
      if (sort) params.append("sort", sort);

      url.search = params.toString();

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            return reject(new Error("Failed to fetch data"));
          }
          return response.json();
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  async getNewsById(id: string): Promise<NewsModel> {
    try {
      const response = await fetch(`${this.baseUrl}/news/detail/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }
      const data: DetailResponse = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching document detail:", error);
      throw error;
    }
  }

  async getNewsBySlug(slug: string): Promise<NewsModel> {
    try {
      const response = await fetch(`${this.baseUrl}/news/detail/${slug}/slug`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch documents");
      }
      const data: DetailResponse = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching document detail:", error);
      throw error;
    }
  }

  async search() {}
}
