import axios from "axios";
import { BaseResponseModel } from "../models/baseResponseModel";
import { ProfileModel, OperationHourModel } from "../models/generalModel";

export default class GeneralRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async profile(): Promise<BaseResponseModel<ProfileModel>> {
    try {
      const response = await axios.get(`${this.baseUrl}/general/profile`);

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async operationalHours(): Promise<BaseResponseModel<OperationHourModel[]>> {
    try {
      const response = await axios.get(
        `${this.baseUrl}/general/operational-hours`,
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}
