import react from "react";

interface SkeletonProps {
  number: number;
  columns?: {
    base?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

const SkeletonNews: React.FC<SkeletonProps> = ({
  number,
  columns = { base: 1, md: 2, lg: 4, xl: 4 },
}) => {
  const baseClass = `grid-cols-${columns.base}`;
  const mdClass = `md:grid-cols-${columns.md}`;
  const lgClass = `lg:grid-cols-${columns.lg}`;
  const xlClass = `xl:grid-cols-${columns.xl}`;
  return (
    <div className={`grid gap-4 ${baseClass} ${mdClass} ${lgClass} ${xlClass}`}>
      {[...Array(number)].map((_, index) => (
        <div key={index} className="card-skeleton">
          <div className="mx-auto max-w-full overflow-hidden rounded-xl border bg-white p-2 shadow-sm lg:p-4">
            <div className="center h-72 w-full animate-pulse rounded-xl bg-gray-300"></div>
            <div className="w-12/12 mb-4 mt-2 h-2 animate-pulse rounded-full bg-gray-300"></div>
            <div className="center flex flex-row items-center pt-2">
              <div className="h-[28px] w-[28px] animate-pulse rounded-md bg-gray-300"></div>
              <span className="ml-2 h-2 w-8/12 animate-pulse rounded-full bg-gray-300"></span>
            </div>
            <div className="w-12/12 mt-2 flex flex-col gap-2">
              <span className="w-12/12 h-2 animate-pulse rounded-full bg-gray-300"></span>
              <span className="w-12/12 h-2 animate-pulse rounded-full bg-gray-300"></span>
              <span className="h-2 w-10/12 animate-pulse rounded-full bg-gray-300"></span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonNews;
