import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import AnimatedSearchField from "@/src/components/atoms/animatedSearch";
import CardSearch from "../molecules/cardSearch";

interface CarouselProps {
  images: string[];
}

const Carousel = ({ images }: { images: string[] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [cardSearchHeight, setCardSearchHeight] = useState<number>(0);
  const cardSearchRef = useRef<HTMLDivElement>(null);
  const desiredQuality = 75;

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const updateHeight = () => {
    if (cardSearchRef.current) {
      const rect = cardSearchRef.current.getBoundingClientRect();
      setCardSearchHeight(rect.height);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      nextImage();
    }, 4000);

    return () => clearInterval(timer);
  }, [images.length]);

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [cardSearchRef]);

  // const imageUrl = `/api/optimize-img?imagePath=assets/images/${images[currentImageIndex]}&quality=${desiredQuality}`;

  return (
    <div className="relative inline-flex w-full items-center justify-center bg-gradient-to-r from-black to-transparent">
      <img
        src={`/assets/images/${images[currentImageIndex]}`}
        // src={imageUrl}
        alt="carousel"
        style={{ height: cardSearchHeight }}
        className="h-screen w-full object-cover transition duration-700 ease-in lg:min-h-[600px]"
      />

      <div className="absolute inset-2">
        <div className="center flex flex-row flex-wrap">
          <div className="center align-center container mx-auto hidden pl-24 pt-24 md:w-full lg:block lg:w-1/2">
            <h1 className="text-8xl text-white">JDIH DPRD</h1>
            <h2 className="text-xl text-white">
              Menyediakan akses mudah dan cepat ke berbagai produk hukum yang
              diterbitkan oleh DPRD Provinsi Bali
            </h2>
          </div>
          <div
            className="align-center container mx-auto items-center justify-center p-4 md:w-full lg:w-1/2"
            ref={cardSearchRef}
          >
            <div className="flex justify-center">
              <CardSearch onHeightChange={updateHeight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
