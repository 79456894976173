import React, { useState, useEffect, useMemo } from "react";
import GridViewNews from "./gridViewNews";
import { NewsModel } from "@/src/models/newsModel";
import NewsRepository from "@/src/repositories/newsRepository";
import SkeletonNews from "../atoms/skeletonNews";
import { useRouter } from "next/router";

const SectionNews: React.FC = () => {
  const [documents, setDocuments] = useState<NewsModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();

  const handleNewsClick = () => {
    router.push("/berita");
  };

  // const newsRepository = new NewsRepository();
  const newsRepository = useMemo(() => new NewsRepository(), []);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        const docs = await newsRepository.fetchAll({ page: 1, limit: 2 });
        setDocuments(docs.data);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  return (
    <div>
      <div className="container-sm">
        <h2 className="text-center text-4xl font-bold">Berita</h2>
        <p className="text-center">
          Dapatkan informasi terkini mengenai kegiatan dan berita seputar DPRD
          Provinsi Bali
        </p>
      </div>
      <div className="my-4 flex justify-center">
        <button
          className="rounded-xl bg-primary px-4 py-2 text-white"
          onClick={handleNewsClick}
        >
          <div className="flex items-center justify-center gap-2">
            Lihat Semua
            <img
              src="assets/icons/u_arrow-up-right.png"
              alt="Arrow Image"
              className="h-5 w-5"
            ></img>
          </div>
        </button>
      </div>
      <div className="">
        {loading ? (
          <SkeletonNews number={4} />
        ) : (
          <GridViewNews data={documents} />
        )}
      </div>
    </div>
  );
};

export default SectionNews;
